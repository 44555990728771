import CallCenterIcon from "@components/svg/callCenterIcon";
import { formatPhoneNumber, getPhoneNumber } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
import { callEvent } from "src/api";
import styles from "./styles.module.scss";
import { CSSProperties } from "react";
import PhoneIcon from "@components/severalbrands/partnerWithUsPage/formSection/icons/phoneIcon";

export default function ProxiedNumber({
    slogan,
    iconColor,
    textColor,
    emptyIcon,
    phoneNumberColor,
    stickyStyle,
    hideSticky,
}: {
    slogan?: string;
    iconColor?: string;
    textColor?: string;
    emptyIcon?: JSX.Element;
    mobileMessage?: string;
    phoneNumberColor?: string;
    stickyStyle?: {
        stickTitleColor?: string;
        stickBtnTxtColor?: string;
        stickBtnBgColor?: string;
        stickBtnBorderColor?: string;
        stickyPulseColor?: string;
        maxWidth?: string;
    };
    hideSticky?: boolean;
}) {
    const {
        category,
        headerOffer,
        stickyOffer,
        domain,
        visitDetails,
        routeConfig,
    } = useDomainContext();

    const domainSlogan = (
        routeConfig?.config?.settings?.slogan || domain.slogan
    )?.replace(/\[s\]/gi, visitDetails?.region ?? "");

    let phoneNumber: string | undefined;
    if (category && headerOffer?.length) {
        phoneNumber = getPhoneNumber(headerOffer[0]);
    } else {
        phoneNumber = "";
    }

    const stickyPhoneNumber = stickyOffer?.length
        ? getPhoneNumber(stickyOffer[0])
        : "";

    let stickyOfferType = "";
    let stickyTitle = "";
    let stickyLink = "";
    let stickyButtonLabel = "";

    if (category && stickyOffer?.length && !hideSticky) {
        if (stickyPhoneNumber) {
            // number Offer
            stickyOfferType = "numberOffer";
            stickyTitle = stickyOffer[0].attributes?.phoneNumberMessage
                ? stickyOffer[0].attributes?.phoneNumberMessage
                : stickyOffer[0].hiddenAttributes?.phoneNumberMessage
                ? stickyOffer[0].hiddenAttributes?.phoneNumberMessage
                : "Call Now to Get the Best Deal";
            stickyLink = `tel:${stickyPhoneNumber}`;
        } else {
            // link Offer
            stickyOfferType = "linkOffer";
            stickyTitle = stickyOffer[0].attributes?.stickyOfferMessage
                ? stickyOffer[0].attributes?.stickyOfferMessage
                : "";
            stickyLink = `/offers/${stickyOffer[0].slug}`;
            stickyButtonLabel = stickyOffer[0].attributes?.stickyOfferBtnLabel
                ? stickyOffer[0].attributes?.stickyOfferBtnLabel
                : "";
        }
    }

    return (
        <>
            {stickyOffer?.length && !hideSticky ? (
                <div
                    style={
                        {
                            "--text-color": stickyStyle?.stickTitleColor,
                            "--button-text-color":
                                stickyStyle?.stickBtnTxtColor ??
                                domain.config?.stickyButtonTextColor,
                            "--button-bg-color":
                                stickyStyle?.stickBtnBgColor ??
                                domain.config?.stickyButtonBgColor,
                            "--button-border-color":
                                stickyStyle?.stickBtnBorderColor,
                            "--button-pulse-color":
                                stickyStyle?.stickyPulseColor ??
                                domain.config?.stickyPulseColor ??
                                "191, 10, 47",
                            "--max-width": stickyStyle?.maxWidth ?? "80rem",
                        } as CSSProperties
                    }
                    className={`${styles["sticky"]} flex`}
                >
                    {stickyTitle && (
                        <h3>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `${stickyTitle} `,
                                }}
                            />
                        </h3>
                    )}
                    <a
                        className="flex items-center"
                        href={`${stickyLink}`}
                        onClick={() => {
                            if (stickyOfferType === "numberOffer") {
                                void callEvent({
                                    event: "Call",
                                    phoneNumber: stickyPhoneNumber as string,
                                });
                            }
                        }}
                    >
                        <span className="text-sm lg:text-lg font-bold md:font-normal flex gap-2 items-center">
                            <span
                                className={`${styles["phoneIcon"]} block h-8 w-8`}
                            >
                                <PhoneIcon color="currentColor" />
                            </span>

                            <span className={styles.number}>
                                {stickyOfferType === "numberOffer"
                                    ? formatPhoneNumber(stickyPhoneNumber)
                                    : stickyButtonLabel}
                            </span>

                            <span
                                className={`hidden md:inline text-lg font-normal ${styles["call-us"]}`}
                            >
                                Call Us?
                            </span>
                        </span>
                    </a>
                </div>
            ) : (
                <></>
            )}
            {phoneNumber ? (
                <div className={`${styles["phoneNumber"]}  hidden md:block`}>
                    <a
                        className={`flex items-center `}
                        href={`tel:${phoneNumber}`}
                        style={
                            {
                                "--text-color": phoneNumberColor ?? textColor,
                                "--icon-color": iconColor ?? textColor,
                            } as CSSProperties
                        }
                        onClick={() => {
                            void callEvent({
                                event: "Call",
                                phoneNumber: phoneNumber as string,
                            });
                        }}
                    >
                        <CallCenterIcon className="w-7 h-7 lg:w-9 lg:h-9 mr-2" />
                        <span className="text-sm lg:text-lg font-bold">
                            {formatPhoneNumber(phoneNumber)}
                        </span>
                    </a>
                    {headerOffer &&
                    (headerOffer[0].hiddenAttributes?.phoneNumberMessage ||
                        headerOffer[0].attributes?.phoneNumberMessage) &&
                    headerOffer[0].hiddenAttributes?.showHeaderPhoneNumber ? (
                        <div className={styles.phoneNumberMessage}>
                            <span
                                style={{
                                    fontSize: "11px",
                                    color:
                                        phoneNumberColor ?? textColor ?? "#000",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: `${
                                        headerOffer[0].hiddenAttributes
                                            ?.phoneNumberMessage ??
                                        headerOffer[0].attributes
                                            ?.phoneNumberMessage
                                    } `,
                                }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <div
                    className="hidden lg:flex items-center"
                    style={
                        {
                            "--text-color": textColor ?? phoneNumberColor,
                        } as CSSProperties
                    }
                >
                    {emptyIcon}

                    <span className={`${styles.slogan} ml-3 `}>
                        {domainSlogan ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `${domainSlogan} `,
                                }}
                            />
                        ) : slogan ? (
                            <span>
                                <span>{slogan[0].toUpperCase()}</span>
                                <span className="lowercase">
                                    {slogan.slice(1)}
                                </span>
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                </div>
            )}
        </>
    );
}
